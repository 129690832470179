import React, { Component } from "react";
import Reward from "react-rewards";
//import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { message, Badge, Icon } from "antd";

class WidgetCTA extends Component {
  handleSeeSettingClick = () => {
    const { t } = this.props;
  };
  handleAddAlertClick = () => {
    const { t } = this.props;
    this.reward.punishMe();
    message.info(t("only-in-pro-alert"));
  };

  render() {
    const { t } = this.props;
    return (
      <div className="widget-cta">
        {this.props.new && (
          <div className="new-cta">
            <span className="widget-cta-text">New</span>
            <span className="widget-cta-icon">
              <Icon theme="twoTone" twoToneColor="grey" type="star" />
            </span>
          </div>
        )}
        {this.props.delete && (
          <div className="delete-cta" onClick={() => this.props.onDelete()}>
            <span className="widget-cta-text">{t("delete")}</span>
            <span className="widget-cta-icon">
              <Icon theme="twoTone" twoToneColor="#f0f0f0" type="delete" />
            </span>
          </div>
        )}
        {this.props.setting && (
          <div className="see-more-cta" onClick={() => this.props.action(true)}>
            <span className="widget-cta-text">{t("see-more")}</span>
            <span className="widget-cta-icon">
              <Icon theme="twoTone" twoToneColor="#FFF" type="setting" />
            </span>
          </div>
        )}
        {this.props.alert && (
        <Reward
          ref={(ref) => {
            this.reward = ref;
          }}
          type="emoji">
          <div className="add-alert-cta" onClick={this.handleAddAlertClick}>
            <span className="widget-cta-text">{t("add-an-alert")}</span>
            <span className="widget-cta-icon">
              <Icon theme="twoTone" twoToneColor="#FFF" type="alert" />
            </span>
          </div>
        </Reward>
        )}
      </div>
    );
  }
}

export default withTranslation()(WidgetCTA);
