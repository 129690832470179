const loc = {
  countries: [],
  states: [],
  cities: [],
};

const loc_reducer = (state = loc, action) => {
  switch (action.type) {
    case "GET_COUNTRIES_SUCCESS":
      let countries = action.payload.data;
      let newCState = Object.assign({}, state, {
        countries: countries,
      });
      return newCState;

    case "GET_STATES_SUCCESS":
      let states = action.payload.data;
      let newSState = Object.assign({}, state, {
        states: states,
      });
      return newSState;

    case "GET_CITIES_SUCCESS":
      let cities = action.payload.data;
      let newcState = Object.assign({}, state, {
        cities: cities,
      });
      return newcState;

    default:
      return state;
  }
};

export default loc_reducer;
