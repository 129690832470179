import React, { Component } from "react";
import API from "./../ApiLayer/index.js";
import { Spin, Switch, Select, Radio, Icon, Form, Input, Tooltip } from "antd";
import { withTranslation } from "react-i18next";
const { Option } = Select;

class componentWatcher extends Component {
  constructor(props) {
    super(props);
    this.state = {
      httpsOn: true,
      fetchingTokens: false,
      foundKw: [],
      possibleVerificationMethods: [
        { value: "200" },
        { value: "kw" },
        { value: "dont" },
      ],
    };
  }
  render() {
    async function infoAboutRoute(value, _) {
      _.setState({ badRoute: false });
      let oldRoute = _.props.current_checker.route_to_check;
      let newRoute = value;
      if (newRoute !== oldRoute || children.length === 0) {
        _.setState({ fetchingTokens: true });
        try {
          let response = await API.getInfoAboutRoute(newRoute);
          if (response.error_status) {
            _.setState({
              badRoute: true,
              errorToolTip: response.error_tooltip,
            });
          }
          if (response.tokens.length > 0) {
            _.setState({ foundKw: response.tokens });
          }
        } catch (e) {
          /* handle error */
          console.log(e);
        }
        _.setState({ fetchingTokens: false });
      }
    }
    const inputRouteChange = (r) => {
      this.setState({ httpsOn: r.includes("https://"), foundKw: [] });
    };
    const { t } = this.props;
    const { getFieldDecorator } = this.props.form;

    const suffixRouteInput = () => {
      let suffix = "";
      if (this.state.badRoute) {
        suffix = (
          <Tooltip title={t(this.state.errorToolTip)} placement="left">
            <Icon style={{ color: "red" }} type="warning" />
          </Tooltip>
        );
      }
      if (this.state.fetchingTokens) {
        suffix = <Icon style={{ color: "green" }} type="loading-o" spin />;
      }
      return suffix;
    };

    let children = this.state.foundKw.map((kw) => (
      <Option value={kw} key={kw}>
        {kw}{" "}
        <span className="found-on-your-site">
          {t("checker-found-on-your-site")}
        </span>
      </Option>
    ));

    return (
      <div className="fragment">
        <Form.Item label={<span>{t("checker-name-label")} </span>}>
          {getFieldDecorator("name_to_check", {
            initialValue: `${this.props.current_checker.name_to_check}`,
            rules: [
              {
                required: true,
                message: t("checker-name-error"),
                whitespace: false,
              },
            ],
          })(<Input placeholder="eg : My Wordpress" />)}
        </Form.Item>
        <Form.Item
          label={
            <span>
              {t("checker-route-label")}
              <Tooltip title={t("checker-route-tooltip")}>
                {" "}
                <Icon type="question-circle-o" />
              </Tooltip>
            </span>
          }>
          {getFieldDecorator("route_to_check", {
            initialValue: `${this.props.current_checker.route_to_check}`,
            rules: [
              {
                pattern: "^https?://",
                required: true,
                message: t("checker-route-error"),
                whitespace: false,
              },
            ],
          })(
            <Input
              suffix={suffixRouteInput()}
              onBlur={(e) => infoAboutRoute(e.target.value, this)}
              onChange={(e) => inputRouteChange(e.target.value)}
              placeholder="eg : https://website.com/ping or http://12.234.56.78:8080/pong"
            />
          )}
        </Form.Item>
        <Form.Item
          label={
            <span> {`${t("checker-checking-method-question-short")} `} </span>
          }>
          {getFieldDecorator("verification_method", {
            initialValue: this.props.current_checker.verification_method,
          })(
            <Select
              onChange={(value) => {
                this.props.changeVerificationMethod(value);
                if (value === "kw" && children.length === 0) {
                  infoAboutRoute(
                    this.props.current_checker.route_to_check,
                    this
                  );
                }
              }}>
              {this.state.possibleVerificationMethods.map((m) => (
                <Option key={m.value} value={m.value}>
                  {t(`checker-checking-method-${m.value}`)}
                  {m.value === "200" && (
                    <>
                      {" "}
                      <Tooltip title={t("checker-checking-method-200-tt")}>
                        <Icon type="question-circle-o" />
                      </Tooltip>
                    </>
                  )}
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item
          style={{
            display: `${
              this.props.temp_verification_method !== "kw" ? "none" : "block"
            }`,
          }}
          label={
            <span>
              {t("checker-kw-label")}{" "}
              <Tooltip title={t("checker-kw-tooltip")}>
                <Icon type="question-circle-o" />
              </Tooltip>
            </span>
          }>
          {getFieldDecorator("keywords_to_check", {
            initialValue: this.props.current_checker.keywords_to_check,
            rules: [
              {
                validator: async (rule, value) => {
                  console.log(this.props.temp_verification_method);
                  console.log(value);
                  if (
                    this.props.temp_verification_method === "kw" &&
                    value.length === 0
                  ) {
                    throw new Error(t("checker-kw-error"));
                  } else {
                    return;
                  }
                },
              },
            ],
          })(
            <Select
              mode="tags"
              tokenSeparators={[","]}
              style={{ width: "100%" }}
              placeholder="eg : ping">
              {children}
              {this.state.fetchingTokens && (
                <Option
                  value={"vautours-fetching-kw"}
                  key={"vautours-fetching-kw"}>
                  <Spin spinning>Loading</Spin>
                </Option>
              )}
            </Select>
          )}
        </Form.Item>
        <Form.Item
          label="Interval"
          style={{
            display: `${
              this.props.temp_verification_method === "dont" ? "none" : "block"
            }`,
          }}>
          {getFieldDecorator("interval_to_check", {
            initialValue: `${String(
              this.props.current_checker.interval_to_check
            )}`,
          })(
            <Radio.Group>
              <Radio.Button value="60">1/{t("Hour")}</Radio.Button>
              <Radio.Button value="360">4/{t("Day")}</Radio.Button>
              <Radio.Button value="1440">1/{t("Day")}</Radio.Button>
            </Radio.Group>
          )}
        </Form.Item>
        <div className="switcher">
          <Form.Item
            name="seo_should_to_check"
            className="a_switch"
            {...this.props.switchItemLayout}
            label={
              <span className="label_switch">
                {t("checker-ssc-label")}{" "}
                <Tooltip title={t("checker-ssc-tooltip")}>
                  <Icon type="question-circle-o" />
                </Tooltip>
              </span>
            }>
            {getFieldDecorator("seo_should_to_check", {
              valuePropName: "checked",
              initialValue: this.props.current_checker.seo_should_to_check,
            })(<Switch checkedChildren="On" unCheckedChildren="Off" />)}
          </Form.Item>

          <Form.Item
            {...this.props.switchItemLayout}
            name="dev_mode_to_check"
            className="a_switch"
            label={
              <span className="label_switch">
                {t("checker-dev-label")}{" "}
                <Tooltip title={t("checker-dev-tooltip")}>
                  <Icon type="question-circle-o" />
                </Tooltip>
              </span>
            }>
            {getFieldDecorator("dev_mode_to_check", {
              valuePropName: "checked",
              initialValue: this.props.current_checker.dev_mode_to_check,
            })(<Switch checkedChildren="On" unCheckedChildren="Off" />)}
          </Form.Item>

          <Form.Item
            {...this.props.switchItemLayout}
            name="ssl_should_to_check"
            className="a_switch"
            label={
              <span className="label_switch">
                {t("checker-ssl-label")}{" "}
                <Tooltip title={t("checker-ssl-tooltip")}>
                  <Icon type="question-circle-o" />
                </Tooltip>
              </span>
            }>
            {getFieldDecorator("ssl_should_to_check", {
              valuePropName: "checked",
              initialValue: this.props.current_checker.ssl_should_to_check,
            })(
              <Switch
                disabled={!this.state.httpsOn}
                //disabled={!this.state.httpsOn || this.props.user.user_plan === "b"}
                checkedChildren="On"
                unCheckedChildren="Off"
              />
            )}
          </Form.Item>
        </div>
      </div>
    );
  }
}

export default withTranslation()(componentWatcher);
