import React from "react";
import AddASERP from "./../containers/Widget/AddASERP.js";
import { Modal } from "antd";
import { withTranslation } from "react-i18next";

class SERPModal extends React.Component {
  render() {
    return (
      <Modal
        id="SERPModal"
        autoFocusButton="ok"
        className="SERPModal SERPComp"
        title={this.props.titleModal}
        visible={this.props.showModal}
        onOk={(e) => this.props.handleOkSERP(e)}
        onCancel={() => this.props.handleCancel()}
        maskClosable={false}
        okText={this.props.okText}
        cancelText={this.props.cancelText}
        okType={this.props.okType}
        okButtonProps={{
          loading: this.props.loading,
          disabled:
            this.props.selected.state == null ||
            this.props.selected.serpKw === "",
        }}>
        <AddASERP
          handleKWChange={this.props.handleKWChange}
          handleCountryChange={this.props.handleCountryChange}
          handleStateChange={this.props.handleStateChange}
          handleCityChange={this.props.handleCityChange}
          selected={this.props.selected}
          is_pro={this.props.is_pro}
          route_to_check={this.props.c.route_to_check}
          input_kw={this.props.input_kw}
          countries={this.props.countries}
          states={this.props.states}
          cities={this.props.cities}
          t={this.props.t}
          c={this.props.c}
        />
      </Modal>
    );
  }
}

export default withTranslation()(SERPModal);
