const checker_is = {
  current_checker: {
    uuid_to_check: "",
    name_to_check: "",
    route_to_check: "",
    keywords_to_check: ["pong"],
    array_failures: [],
  },
  checkers: [
    {
      name_to_check: "My Website",
      route_to_check: "192.168.86.56/ping:5000",
      uuid_to_check: "loading-uuid",
      dev_mode_to_check: false,
    },
  ],
};

const checker_reducer = (state = checker_is, action) => {
  switch (action.type) {
    case "RESET_CHECKER":
      let newrState = Object.assign({}, state, {
        current_checker: {
          uuid_to_check: "",
          name_to_check: "",
          route_to_check: "",
          keywords_to_check: ["pong"],
          array_failures: [],
        },
      });
      return newrState;

    case "UPDATE_CHECKER_SUCCESS":
      let uChecker = action.payload.data;
      let newuState = Object.assign({}, state, {
        current_checker: uChecker,
        checkers: state.checkers.map((c) => {
          if (c.uuid_to_check === action.payload.data.uuid_to_check) {
            return action.payload.data;
          }
          return c;
        }),
      });
      return newuState;

    case "GET_CHECKER_SUCCESS":
      console.log(action.payload)
      let gChecker = [...action.payload];
      let newgState = Object.assign({}, state, {
        checkers: [...gChecker],
      });
      return newgState;

    case "DETAIL_CHECKER_SUCCESS":
      if (action.payload.array_failures === undefined) {
        action.payload.array_failures = [];
      }
      let newdState = Object.assign({}, state, {
        current_checker: {
          ...action.payload,
          keywords_to_check: [...action.payload.keywords_to_check],
          array_failures: [...action.payload.array_failures],
        },
      });
      return newdState;

    case "POST_SERP_CHECKER_SUCCESS":
      let newSState = Object.assign({}, state, {
        current_checker: {
          ...state.current_checker, 
          serp_kws: [...state.current_checker.serp_kws, action.payload.new_serp],
        },
      });
      return newSState;


    default:
      return state;
  }
};

export default checker_reducer;
