import React, { Component } from "react";
import { Player } from "video-react";

import SwiperCore, { Pagination, Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import i18n from "./../my-i18next.js";

import { withTranslation } from "react-i18next";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import DetailNotified from "./DetailNotified";
import { Modal, Icon } from "antd";
import auth0Client from "./../auth0.js";

SwiperCore.use([Pagination, Navigation, Autoplay]);
const API_URL = process.env.REACT_APP_API_URL;

class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current_slide: 0,
      visible: false,
    };
  }
  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  hideModal = () => {
    this.setState({
      visible: false,
    });
  };

  pause = () => {
    this.player.pause();
  };
  render() {
    const { t } = this.props;
    const signIn = () => {
      fetch(`${API_URL}/`);
      auth0Client.signIn();
    };
    const changeLanguage = () => {
      let new_lg = this.props.i18n.language === "en" ? "fr" : "en";
      i18n.changeLanguage(new_lg);
    };
    return (
      <Router>
        <Switch>
          <Route
            path="/notified/:token"
            render={(props) => <DetailNotified {...props} {...this.props} />}
          />
          <Route>
            <div id="homeOut">
              <div id="homeOut-inside">
                <div className="switch-lg" onClick={() => changeLanguage()}>
                  {t("ho-lg")}
                </div>
                <div className="ho-title">
                  <span>{t("ho-welcome")}</span>
                  <br />
                  Seo-Metrics.net
                </div>
              </div>
              <Swiper
                loop
                lazy
                speed={1000}
                autoplay={{
                  delay: 8000,
                }}
                className="swiper ucs"
                breakpoints={{
                  720: {
                    slidesPerView: 1,
                    spaceBetween: 0,
                  },
                  1200: {
                    slidesPerView: 1.3,
                    spaceBetween: 100,
                  },
                }}
                navigation={{
                  prevEl: ".prev",
                  nextEl: ".next",
                }}
                grabCursor
                onSlideChange={(swiper) =>
                  this.setState({ current_slide: swiper.activeIndex })
                }>
                {[1, 2].map((n) => (
                  <SwiperSlide key={`slide-key-${n}`}>
                    <div className="slide">
                      <div className="slide-inside">
                        <div className="slide-title">{t(`ho-title-${n}`)}</div>
                        <div className="slide-desc">{t(`ho-desc-${n}`)}</div>
                      </div>
                      <div className="slide-img">
                        <img src={`/homeout${n}.png`} alt="" />
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
              <div className="ho-cta">
                <div className="ho-gs" onClick={signIn}>
                  {t(`ho-gs`)}
                </div>
                <div className="ho-or">{t(`ho-or`)}</div>
                <div className="ho-see" onClick={this.showModal}>
                  {t("ho-see")}
                  <Icon type={"video-camera"} />
                </div>
              </div>
              <Modal
                visible={this.state.visible}
                footer={null}
                onCancel={this.hideModal}
                afterClose={this.pause}
                bodyStyle={{ padding: 0 }}>
                <Player
                  autoPlay
                  ref={(ref) => {
                    this.player = ref;
                  }}>
                  <source
                    src="https://s3.eu-central-1.amazonaws.com/staging.vautours.net/MotionMintox-Vautours+01-08.mp4"
                    type="video/mp4"
                  />
                </Player>
              </Modal>
            </div>
          </Route>
        </Switch>
      </Router>
    );
  }
}

export default withTranslation()(index);
