import { put, takeLatest } from "redux-saga/effects";
import { detailUser, updateDashboardLayout } from "./user-sagas.js";
import {
  getCheck,
  postCheck,
  deepDetailCheck,
  detailCheck,
  updateCheck,
  deleteCheck,
  healthyCheck,
  postSERPCheck,
  deleteSERPCheck,
} from "./checker-sagas";
import {
  getWatch,
  postWatch,
  detailWatch,
  deleteWatch,
  updateWatch
} from "./watcher-sagas";
import {
  detailNotified,
  deleteNotified,
  getNotified,
  verifyNotified,
  inviteNotified,
  getSlackFromCode
} from "./notified-sagas";
import {
  getCountries,
  getStates,
  getCities,
} from "./location-sagas";

function* allSaga() {
  //Checker
  yield takeLatest("GET_CHECKER", getCheck);
  yield takeLatest("POST_CHECKER", postCheck);
  yield takeLatest("DEEP_DETAIL_CHECKER", deepDetailCheck);
  yield takeLatest("DETAIL_CHECKER", detailCheck);
  yield takeLatest("UPDATE_CHECKER", updateCheck);
  yield takeLatest("DELETE_CHECKER", deleteCheck);
  yield takeLatest("HEALTHY_CHECKER", healthyCheck);
  yield takeLatest("POST_SERP_CHECKER", postSERPCheck);
  yield takeLatest("DELETE_SERP_CHECKER", deleteSERPCheck);
  //Watcher
  yield takeLatest("GET_WATCHER", getWatch);
  yield takeLatest("POST_WATCHER", postWatch);
  yield takeLatest("DETAIL_WATCHER", detailWatch);
  yield takeLatest("UPDATE_WATCHER", updateWatch);
  yield takeLatest("DELETE_WATCHER", deleteWatch);
  //NOTIFIED
  yield takeLatest("DETAIL_NOTIFIED", detailNotified);
  yield takeLatest("GET_NOTIFIED", getNotified);
  yield takeLatest("VERIFY_NOTIFIED", verifyNotified);
  yield takeLatest("INVITE_NOTIFIED", inviteNotified);
  yield takeLatest("DELETE_NOTIFIED", deleteNotified);
  yield takeLatest("GET_SLACK_FROM_CODE", getSlackFromCode);
  //USER
  yield takeLatest("DETAIL_USER", detailUser);
  yield takeLatest("UPDATE_DASHBOARD_LAYOUT", updateDashboardLayout);
  //Location
  yield takeLatest("GET_COUNTRIES", getCountries);
  yield takeLatest("GET_STATES", getStates);
  yield takeLatest("GET_CITIES", getCities);
}

export default allSaga;
