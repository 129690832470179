import React from "react";
import WidgetCTA from "./WidgetCTA.js";
import { useTranslation } from "react-i18next";
import { Progress, Icon, Button } from "antd";

const Score = (props) => {
  const { t } = useTranslation();
  let last_score = props.data[25].y;
  return (
    <div className="item item-score">
      <WidgetCTA />
      <div
        className={`item-content item-content-score ${
          props.dragEnabled && "drag-me"
        }`}>
        <div className="item-score-left">
          <Progress
            type="dashboard"
            strokeColor={{
              "20%": "#87d068",
              "100%": "#108ee9",
            }}
            percent={Number(Number.parseFloat(last_score).toFixed(0))}
          />
        </div>

        <div className="item-score-right">
          <div className="header">{t(props.dataSet)}</div>
          <div className="desc">{t(`${props.dataSet}-desc`)}</div>
        </div>
      </div>
    </div>
  );
};

export default Score;
