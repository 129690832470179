export const detail = {
  en: {
    "no-download-file": "No HTML downloaded",
    "download-file": "Download HTML",
    "detail-historic-title": "Downtimes Historic",
    "detail-historic-cal-nofail": "No failure detected",
    "detail-historic-file-tt-first": "We had detected a failed check this ",
    "detail-historic-file-tt-last": "Click here to see the file (html).",
    "detail-never-failed": "No file to show.",
    average: "Avg",
    unknown: "unknown",
    "btn-see-report": "See the report",
    delete: "Delete",
    update: "Update",
    about: "About",
    "seo-section-title": "Performance KPIs",
    "seo-not-yet":
      "We Are Currently Checking For The SEO. We will send you a mail once it is done.",
    "drag-me": "Drag Me",
    "save-the-layout": "Save your changes",
    "edit-the-layout": "Change the layout",
    discard: "Discard",
    metrics: "Metrics",
    monitoring: "Monitoring",
    timeInSecond: "In Seconds",
    largestContentfulPaint: "Largest Contentful Paint",
    "largestContentfulPaint-desc":
      "measures when the largest content element in the viewport becomes visible.",
    interactive: "Largest Contentful Paint",
    "interactive-desc": "how long it takes a page to become fully interactive.",
    speedIndex: "Speed Index",
    "speedIndex-desc":
      "how quickly content is visually displayed during page load.",
    firstContentfulPaint: "First Contentful Paint",
    "firstContentfulPaint-desc":
      "How long it takes the browser to render the first piece of DOM",
    "see-more": "See more",
    "add-an-alert": "Add an alert",
    "only-in-pro-alert": "To add an alert you must be a PRO member",

    accessibility: "Accessibility",
    "accessibility-desc":
      "Scores the ease of use of the site for people with difficulties or disabilities. ",
    seo: "SEO",
    "seo-desc":
      "How well the user-facing and technical aspects of your site contribute to search engine optimization, higher rankings and organic traffic.",
    performance: "Performance",
    "performance-desc":
      "Score based on all the speed metrics of your website/server.",
    "best-practices": "Best Practices",
    "best-practices-desc":
      "A general note on the health of your website code. Tests include readability, User Experience, ...",
    "last-report-text": "Last report",
    news: "News",
    marketplace: "Marketplace",
    interested_in: "Would you be interested?",
    desc_marketplace_1:
      "Here you could find a list of experts who can help you with the technologies you use.",
    desc_marketplace_2:
      "In case of an emergency, you could get in touch with an expert who could help you optimize your Django application or secure your Wordpress, etc.  We would automatically detect the technologies you are using and show you the most qualified experts.  If this is something that could help us, feel free to click the button below.",
    desc_news_1:
      "Here you could find a list of articles related to the technologies you are using.",
    desc_news_2:
      "For example, you might see articles about security on Django or how to optimize your SEO on WordPress, etc.  We would automatically detect what technologies you are using and show you articles from Reddit, Hackernews, Medium, or other sources.  If this is something that could help you, feel free to click the button below.",
    warning_vote:
      "* We may send you an email to ask for more information to tailor our offer, but this does not represent a purchase in any way.",
    i_would_be_interested: "Sound interesting!",
    i_am_interested: "Thanks for your vote!",
    ssl_not_yet_loaded:
      "We are analyzing the validity of your SSL certificate, reload this page in a few moments.",
    ssl_cant_request:
      "You have already made too many request recently. Wait for a few minutes.",
    ssl_ask_request: "Re-check the certicate.",

    list_modal_serp_title: "Track the performance of a keyword 🚀",
    content_delete_modal_serp_title:
      "You are going to delete this keyword, by doing so you will also delete all the historic of its position. This action cannot be undone.",
    enter_serp_kw: "The keyword you want to track",
    kw_to_track: "Word(s) to track",
    enter_your_country: "Search Country",
    enter_your_state: "State",
    enter_your_city: "City",
    add_kw: "Track this 🚀",
    cta_add_a_serp: "Track a keyword 🚀",
  },
  fr: {
    "no-download-file": "Aucun rapport",
    "download-file": "Télécharger l'HTML",
    "detail-historic-title": "Historique des alertes",
    "detail-historic-cal-nofail": "Pas de problème détecté",
    "detail-historic-file-tt-first": "Nous avons détecté un problème ce ",
    "detail-historic-file-tt-last": "Cliquez ici pour voir le fichier (html).",
    "detail-never-failed": "Pas de fichier à montrer.",
    discard: "Annuler",
    "last-report-text": "Dernier rapport",
    average: "Moy",
    unknown: "inconnu",
    "btn-see-report": "Consulter le rapport",
    delete: "Supprimer",
    update: "Modifier",
    about: "À propos",
    "seo-section-title": "KPIs de Performance",
    "seo-not-yet":
      "Nous faisons le test SEO en ce moment. Nous vous envoyons un mail dès que c'est fait.",
    "drag-me": "Fais moi glisser",
    "save-the-layout": "Sauver les changements",
    "edit-the-layout": "Changer le dashboard",
    metrics: "Metrics",
    monitoring: "Monitoring",
    timeInSecond: "En Seconds",
    largestContentfulPaint: "Largest Contentful Paint",
    "largestContentfulPaint-desc":
      "measures when the largest content element in the viewport becomes visible.",
    interactive: "Largest Contentful Paint",
    "interactive-desc": "how long it takes a page to become fully interactive.",
    speedIndex: "Index de Vitesse ",
    "speedIndex-desc":
      "how quickly content is visually displayed during page load.",
    firstContentfulPaint: "First Contentful Paint",
    "firstContentfulPaint-desc":
      "How long it takes the browser to render the first piece of DOM",
    "see-more": "Voir plus",
    "add-an-alert": "Ajouter une alerte",
    "only-in-pro-alert":
      "Vous devez être un membre PRO pour ajouter une alerte",

    accessibility: "Accessibilité",
    "accessibility-desc":
      "Donne un indice sur la facilité d'utilisation du site pour les personnes ayant des difficultés ou des handicaps. ",
    seo: "SEO",
    "seo-desc":
      "Mesure de l'optimisation de votre site afin d'accroître sa visibilité pour des recherches organiques pertinentes.",
    performance: "Performance",
    "performance-desc":
      "Score basé sur l'ensemble des mesures de vitesse de votre site/serveur.",
    best_practices: "Bonnes Pratiques",
    "best-practices-desc":
      "Une note générale sur la santé du code de votre site web.",

    news: "News",
    marketplace: "Marketplace",

    interested_in: "Seriez-vous intéressé ?",
    desc_marketplace_1:
      "Ici, vous pourriez trouver une liste d’expert pouvant vous aider avec les technologies que vous utilisez.",
    desc_marketplace_2:
      "Par exemple, vous pourriez voir un expert pouvant vous aider avec une migration sur Django ou une optimisation SEO sur Wordpress, etc.  Nous détecterions automatiquement les technologies que vous utilisez et vous montrerions des experts qualifiés.  Si c’est quelque chose qui pourrait nous aider, n’hésitez pas à cliquer sur le bouton ci-dessous.",
    desc_news_1:
      "Ici, vous pourriez trouver une liste d’article en rapport avec les technologies que vous utilisez.",
    desc_news_2:
      "Nous détecterions automatiquement les technologies que vous utilisez et vous montrerions des articles de Reddit, Hackernews, Medium ou d’autres sources.  Si c’est quelque chose qui pourrait vous aider, n’hésitez pas à cliquer sur le bouton ci-dessous.",
    warning_vote:
      "* Nous vous enverrons peut-être un mail pour vous demander plus d’informations sur vos envies, mais ceci ne représente en aucun cas un achat.",
    i_would_be_interested: "C'est interessant!",
    i_am_interested: "Merci pour votre vote!",
    ssl_not_yet_loaded:
      "Nous analysons la validité de votre certificat SSL, rechargez la page dans quelques instants.",
    ssl_cant_request:
      "Vous avez fait trop de demandes récemment. Attendez quelques minutes.",
    ssl_ask_request: "Re-tester le certificat",

    list_modal_serp_title: "Suivre la performance SEO d'un mot-clé 🚀",
    enter_serp_kw: "Le mot-clé que vous souhaitez suivre",
    kw_to_track: "Mot(s) à suivre",
    enter_your_country: "Pays de la recherche",
    enter_your_state: "Province",
    enter_your_city: "Ville",
    add_kw: "Suivre ce mot-clé 🚀",
    cta_add_a_serp: "Ajouter un mot-clé 🚀",
    content_delete_modal_serp_title:
      "Vous allez supprimer ce mot-clé, ce faisant vous supprimerez également tout l'historique de sa position. Cette action ne peut pas être annulée.",
  },
};
