export const post = {
  en: {
    cancel: "Cancel",
    previous: "Previous",
    close: "Close",
    "go-see-it": "Let's Go See It",
    "empty-list-title": "You haven't created a checker yet.",
    "empty-list-desc": "Let's Get Started Together in 3 clicks.",
    "empty-list-button": "Let's Create One",

    "create-checker": "Create Another Checker 🎉",
    "card-not-yet-title": "Hey There",
    "card-not-yet-1": "Let's get started 🎉",

    "add-ressource": "The website to monitor.",
    "add-feature": "How can we help you?",
    "add-watcher": "Who we should alert?",

    "checker-name-label": "Name",
    "checker-name-tooltip": "Name your new checker",
    "checker-name-error": "Please name your ressource",
    "checker-route-label": "Route",
    "checker-route-tooltip":
      "Please enter a route to check starting with http(s)://",
    "checker-route-error":
      "Please enter a route to check starting with http(s)://",

    "checker-checking-method-question": "Should we monitor your website",
    "checker-checking-method-question-short": "How to monitor ?",
    "checker-checking-method-200-tt": "Simply means we will check to make sure your website returns a HTTP 200 status.",
    "checker-checking-method-200": "Yes, simply (recommended)",
    "checker-checking-method-dont": "No, please don't",
    "checker-checking-method-kw": "Yes. Check for a certain word",


    "checker-found-on-your-site": "found on your site's HTML",
    "checker-kw-label": "Word to search",
    "checker-kw-tooltip": "The keywords expected on an healthy page",
    "checker-kw-error": "Please enter one or more keywords to look for",

    "checker-interval-tooltip": "How long should we wait between every check?",

    "checker-ssl-label": "SSL Monit.",
    "checker-ssl-tooltip":
      "Check the Validity of your httpS certificate. Only available for HTTPS routes",
    "checker-dev-label": "Dev Mode",
    "checker-dev-tooltip":
      "In dev mode you wont receive an alert on failure. Only the status will change.",
    "checker-ssc-label": "KPIs",
    "checker-ssc-tooltip":
      "Do you want us to track SEO, Performance, Best Practices and Accessibility KPIs ?",

    "watcher-add-watcher": "Add a watcher",
    "watcher-name-label": "Name",
    "watcher-name-error": "Please name your ressource",
    "watcher-name-tooltip":
      "The name of to the (group) of user(s) you would like to alert.",
    "watcher-notified-label": "Notifed(s)",
    "watcher-slack-label": "Slack",
    "watcher-slack-add-to": "Add To Slack",
    "watcher-notified-error": "Please enter one or more notified",
    "watcher-notified-tooltip":
      "Enter the e-mail(s) adress(es) you want us to send an email to.",
    "watcher-notified-too-much-error":
      "Your plan allows you to notify only {{maxNotified}} people per Watcher",
    "watcher-message-label": "Alert Message",
    "watcher-message-error": "Please enter an alert message",
    "next-button": "Next",
    "add-button": "Add checker",
    "checker-create-a-new-one": "Create another one",
    "checker-go-see-it": "Go see it!",
    "seo_should_to_check-title": "Search Engine Optimization",
    "dev_mode_to_check-title": "Developpement Mode",
    "ssl_should_to_check-title": "SSL Monitoring",
    "seo_should_to_check-desc":
      "Get A Ranking Of Different Parameters Impacting Your Search Engine Score. Get Info About Your Accessibility, Performances And Even Specific Tips About SEO",
    "dev_mode_to_check-desc":
      "In dev mode you wont receive an alert on failure. Only the status will change. Select this option if you are still working on your website.",
    "ssl_should_to_check-desc":
      "Check the Validity of your httpS certificate. Only available for HTTPS routes. Get the number of days remaining until your SSL certificate is expired.  ",
    "Hour": "Hour",
    "Day": "Day",
  },
  fr: {
    cancel: "Annuler",
    previous: "Précedent",
    close: "Fermer",
    "go-see-it": "Voir Le Résultat",
    "empty-list-title": "Vous n'avez pas encore de checker.",
    "empty-list-desc": "Commençons ensemble en trois cliques.",
    "empty-list-button": "En Créer un",

    "checker-name-label": "Nom",
    "checker-name-tooltip": "Donnez un nom à votre nouveau Checker",
    "checker-name-error": "Donnez un nom à votre checker",
    "checker-route-label": "Route",
    "checker-route-tooltip":
      "Entrer un URL à vérifier. N'oubliez pas le http(s)://",
    "checker-route-error":
      "L'URL/IP doit être valide. N'oubliez pas le http(s)://",

    "checker-checking-method-question": "Devons-nous surveiller votre site",
    "checker-checking-method-question-short": "Monitoring ?",
    "checker-checking-method-200-tt": "Simplement - Nous verifirons que votre site retourne un statut HTTP200.",
    "checker-checking-method-200": "Oui, simplement (recommendé)",
    "checker-checking-method-dont": "Non, pas besoin",
    "checker-checking-method-kw": "Oui. Verifier qu'un mot est présent.",

    "checker-found-on-your-site": "présent dans le code de votre site",
    "checker-kw-label": "Mot Clé(s)",
    "checker-kw-tooltip": "Les mots clés attendus sur une page en santé.",
    "checker-kw-error":
      "Veuillez saisir un ou plusieurs mots clés à rechercher",
    "checker-interval-tooltip": "À quel interval devons vérifier votre site ?",
    "checker-ssl-label": "Certi. SSL",
    "checker-ssl-tooltip":
      "Vérification de la validité de votre certificat SSL. Seulement valable pour les routes httpS://",
    "checker-dev-label": "Dev Mode",
    "checker-dev-tooltip":
      "En mode dev, vous ne serez pas averti en cas d'échec. Seul le statut changera.",
    "checker-ssc-label": "Métriques",
    "checker-ssc-tooltip":
      "Voulez-vous que nous vérifions le SEO, la performance, et le respect des bonnes pratiques de votre site ?",

    "watcher-add-watcher": "Ajoutez un watcher",
    "watcher-name-label": "Nom",
    "watcher-name-error": "Donnez un nom à votre checker",
    "watcher-name-tooltip":
      "Le nom du ou des utilisateurs que vous souhaitez alerter.",
    "watcher-notified-label": "À Notifier",
    "watcher-slack-label": "Slack",
    "watcher-slack-add-to": "Ajouter à Slack",
    "watcher-notified-error":
      "Veuillez saisir une ou plusieurs personnes à notifier.",
    "watcher-notified-too-much-error":
      "Votre plan ne vous permet de notifier que {{maxNotified}} personnes par Watcher",
    "watcher-notified-tooltip":
      "Saisissez la ou les e-mails auxquels vous souhaitez que nous avertissions.",
    "watcher-message-label": "Message",
    "watcher-message-error": "Entrez un message d'alerte",
    "next-button": "Suivant",
    "add-button": "Créer le checker",
    "checker-create-a-new-one": "Créer un autre",
    "checker-go-see-it": "Aller sur la page!",
    "create-checker": "Créons Un Autre Checker 🎉",
    "card-not-yet-title": "Hello",
    "card-not-yet-1": "Créons votre 1er Checker 🎉",

    "add-ressource": "Le site à surveiller.",
    "add-feature": "Comment pouvons nous vous aider ?",
    "add-watcher": "Le(s) personne(s) à avertir.",
    "seo_should_to_check-title": "Search Engine Optimization",
    "dev_mode_to_check-title": "Developpement Mode",
    "ssl_should_to_check-title": "SSL Monitoring",
    "seo_should_to_check-desc":
      "Obtenez votre score SEO, mais aussi des informations sur la Performance, l'Accessibilité et même des conseils spécifiques sur le référencement",
    "dev_mode_to_check-desc":
      "En mode développement, vous ne serez pas averti en cas d'échec. Seul le statut changera. Sélectionnez cette option si vous changez encore certaines choses sur votre site.",
    "ssl_should_to_check-desc":
      "Vérification de la validité de votre certificat SSL. Seulement valable pour les routes httpS://. Vous verrez ainsi quand vos certificats arrivent à expiration.",
    "Hour": "Heure",
    "Day": "Jour",
  },
};
