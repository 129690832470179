import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import DetailDataComp from "./../containers/DetailDataComp.js";
import NewsComp from "./../containers/NewsTab/NewsComp.js";
import MarketPlace from "./../containers/MarketPlace.js";
import { DashboardGrid } from "./../containers/DashboardGrid.js";
import API from "./../ApiLayer/index.js";
import { withTranslation } from "react-i18next";
import { Button, Tag, Icon, Spin } from "antd";
import { Tabs } from "antd";
import AskModal from "./../containers/AskModal.js";
import SERPModal from "./../containers/SERPModal.js";
import DisplayASerp from "./../containers/Widget/DisplayASerp.js";

const { TabPane } = Tabs;

class Index extends Component {
  constructor(props) {
    super(props);
    const { t } = this.props;
    this.gridRef = React.createRef();
    this.props.reset_checker();
    this.props.reset_watcher();
    let uuid_to_check = this.props.match.params.uuid_to_check;
    this.props.deep_detail_checker(uuid_to_check);
    this.state = {
      selected_watcher: {},
      arr_signed_url: [],
      uuid_to_check: uuid_to_check,
      name_to_check: "",
      // TODO:  <03-08-20 modal should be abstracted> //
      showSlackModal: false,
      okSlackType: "primary",
      okSlackText: t("list-modal-healthy-ok"),
      titleModal: t("list-modal-delete-title"),
      contentSlackModal: t("list-modal-healthy-content"),
      dragEnabled: false,
      activeKey: "",
      showDeleteModal: false,
      okDeleteType: "danger",
      cancelDeleteText: t("list-modal-delete-cancel"),
      okDeleteText: t("list-modal-delete-ok"),
      titleSERPModal: t("list_modal_serp_title"),
      contentDeleteModal: t("list-modal-delete-content"),
      ContentDeleteSERPModal: t("content_delete_modal_serp_title"),
      // SERP
      okText: t("add_kw"),
      okType: "primary",
      showSERPModal: false,
      selected: {
        serpKw: "",
        country: null,
        state: null,
        city: null,
      },
      objToDelete: { itemType: "", itemId: "" },
    };
  }

  // >>handling the delete should be facto

  onDelete(objToDelete) {
    this.setState({
      showDeleteModal: true,
      objToDelete: objToDelete,
    });
  }

  handleCancel = () => {
    this.setState({
      showHealthyModal: false,
      showDeleteModal: false,
    });
  };

  handleOkDelete = () => {
    this.setState({
      showDeleteModal: false,
    });
    if (this.state.objToDelete.itemType === "checker") {
      debugger;
      this.props.delete_checker(this.props.match.params.uuid_to_check);
      this.props.get_checker();
      setTimeout(function () {
        window.location.href = "/";
      }, 1000);
    } else if (this.state.objToDelete.itemType === "serp") {
      this.props.delete_serp_kw(this.state.objToDelete.itemId);
      this.props.deep_detail_checker(this.props.match.params.uuid_to_check);
      this.gridRef.current.refreshItems();
    }
    this.setState({
      objToDelete: { itemType: "", itemId: "" },
    });
  };

  handleOkSERP = () => {
    // get uuid_to_check from url
    let uuid_to_check = this.props.match.params.uuid_to_check;
    this.props.post_serp_kw({
      ...this.state.selected,
      uuid_to_check,
    });
    // refresh after 500s
    setTimeout(() => {
      this.gridRef.current.refreshItems();
      this.toggleSERPModal();
    }, 500);
  };

  toggleSERPModal = () => {
    if (this.props.countries.length === 0 && !this.props.loading_location) {
      this.props.get_countries();
    }
    this.setState({ showSERPModal: !this.state.showSERPModal });
  };

  handleKWChange = (new_kw) => {
    let new_selected = this.state.selected;
    new_selected.serpKw = new_kw;
    this.setState({ selected: new_selected });
  };

  handleCountryChange = (selectedCountry, e) => {
    let new_selected = this.state.selected;
    new_selected.country = selectedCountry;
    new_selected.state = null;
    new_selected.city = null;
    new_selected.location = e.props.app_id;
    this.setState({ selected: new_selected });
    this.props.get_states(e.props.app_id);
  };

  handleStateChange = (selectedState, e) => {
    let new_selected = this.state.selected;
    new_selected.state = selectedState;
    new_selected.city = null;
    new_selected.location = e.props.app_id;
    this.setState({ selected: new_selected });
    this.props.get_cities(e.props.app_id);
  };

  handleCityChange = (selectedCity, e) => {
    this.setState({
      selected: {
        ...this.state.selected,
        city: selectedCity,
        location: e.props.app_id,
      },
    });
  };

  get_signed_url = async (uuid_to_s, r_for) => {
    let arr = this.state.arr_signed_url;
    arr.push(uuid_to_s);
    this.setState({
      arr_signed_url: arr,
    });
    let oarr = arr.filter((x) => x !== uuid_to_s);
    try {
      const signedUrl = await API.getSignedUrl(uuid_to_s, r_for);
      window.open(signedUrl.url, "_blank");
    } catch (err) {
      //console.log(err);
    }
    this.setState({
      arr_signed_url: oarr,
    });
  };

  handleDragEnableChange = (bool = true) => {
    if (!bool) {
      this.setState({ dragEnabled: !this.state.dragEnabled });
      return;
    }
    let oldDragState = this.state.dragEnabled;
    this.setState({ dragEnabled: !this.state.dragEnabled });
    if (oldDragState === true) {
      // means new layout
      let layout = [];
      let layoutItems = this.gridRef.current.getItems();
      layoutItems.map((x) => layout.push(x._component.key));
      if (
        this.props.user.layout === undefined ||
        JSON.stringify(this.props.user.layout) !== JSON.stringify(layout)
      ) {
        this.props.update_dashboard_layout(layout);
      }
    }
  };

  getActiveKey = () => {
    if (this.state.activeKey !== "") {
      return this.state.activeKey;
    } else {
      if (this.props.location.hash == "#SEO") {
        return "1";
      } else {
        return "2";
      }
    }
  };

  changeTabKey = (activeKey) => {
    this.setState({ activeKey: activeKey });
  };

  render() {
    const { t } = this.props;
    const c = this.props.current_checker;
    const mobile = window.matchMedia("(max-width: 900px)").matches;
    return (
      <div className="DetailCheckItem">
        <SERPModal
          cancelText={this.state.cancelDeleteText}
          okText={this.state.okText}
          okType={this.state.okType}
          showModal={this.state.showSERPModal}
          titleModal={this.state.titleSERPModal}
          contentModal={this.state.contentDeleteModal}
          countries={this.props.countries}
          states={this.props.states}
          cities={this.props.cities}
          handleOkSERP={(e) => {
            this.handleOkSERP(e);
          }}
          handleCancel={() => {
            this.toggleSERPModal();
          }}
          is_pro={this.props.user.user_plan === "p"}
          handleKWChange={this.handleKWChange}
          handleCountryChange={this.handleCountryChange}
          handleStateChange={this.handleStateChange}
          handleCityChange={this.handleCityChange}
          selected={this.state.selected}
          t={this.props.t}
          c={c}
        />
        <AskModal
          cancelText={this.state.cancelDeleteText}
          okText={this.state.okDeleteText}
          okType={this.state.okDeleteType}
          showModal={this.state.showDeleteModal}
          titleModal={this.state.titleModal}
          contentModal={this.state.contentDeleteModal}
          handleOk={() => {
            this.handleOkDelete();
          }}
          handleCancel={() => {
            this.handleCancel();
          }}
        />
        <div className="DetailCheckItem-header">
          <div className="DetailCheckItem-header-left">
            <div className="DetailCheckItem-title">{c.name_to_check}</div>
            <div className="DetailCheckItem-route">{c.route_to_check}</div>
          </div>
          <div className="DetailCheckItem-header-right">
            <Link to={`/update/${c.uuid_to_check}`}>
              <Button
                className="btn"
                onClick={() => this.handleDragEnableChange()}>
                {t("update")}
                <Icon type="setting" />
              </Button>
            </Link>
            <Button
              className="btn"
              type="danger"
              onClick={() =>
                this.onDelete({ itemType: "checker", itemId: "" })
              }>
              {t("delete")}
              <Icon
                style={{ fontSize: "16px" }}
                type="delete"
                theme="twoTone"
                twoToneColor="white"
              />
            </Button>
          </div>
        </div>
        <Tabs
          activeKey={this.getActiveKey()}
          animated={false}
          centered={true}
          onChange={this.changeTabKey}>
          <TabPane tab={t("metrics")} key="1" disabled={!c.seo_should_to_check}>
            <Spin
              spinning={c.seo_report === undefined || c.seo_report.length === 0}
              tip={t("seo-not-yet")}>
              <DashboardGrid
                toggleSERPModal={this.toggleSERPModal}
                mobile={mobile}
                t={this.props.t}
                layout={this.props.user.layout}
                gridRef={this.gridRef}
                dragEnabled={this.state.dragEnabled}
                onDelete={(objToDelete) => this.onDelete(objToDelete)}
                c={c}
              />
            </Spin>
          </TabPane>
          <TabPane tab={t("monitoring")} key="2">
            <DetailDataComp
              {...this.props}
              arr_signed_url={this.state.arr_signed_url}
              get_signed_url={this.get_signed_url}
            />
          </TabPane>
          <TabPane tab={t("marketplace")} key="3">
            <MarketPlace {...this.props} />
          </TabPane>
          {false && (
            <TabPane tab={t("news")} key="4">
              <NewsComp {...this.props} />
            </TabPane>
          )}
        </Tabs>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    get_watcher: () => dispatch({ type: "GET_WATCHER" }),
    get_checker: () => dispatch({ type: "GET_CHECKER" }),
    get_countries: () => dispatch({ type: "GET_COUNTRIES" }),
    get_states: (country_selected) =>
      dispatch({ type: "GET_STATES", payload: country_selected }),
    get_cities: (state_selected) =>
      dispatch({ type: "GET_CITIES", payload: state_selected }),
    post_watcher: (data) => API.postWatch(data),
    //getting info
    post_serp_kw: (data) =>
      dispatch({
        type: "POST_SERP_CHECKER",
        payload: data,
      }),
    deep_detail_checker: (data) =>
      dispatch({ type: "DEEP_DETAIL_CHECKER", payload: data }),
    //update info
    delete_serp_kw: (uuid_to_serp) =>
      dispatch({
        type: "DELETE_SERP_CHECKER",
        payload: uuid_to_serp,
      }),
    delete_checker: (uuid) =>
      dispatch({ type: "DELETE_CHECKER", payload: uuid }),
    reset_checker: () => dispatch({ type: "RESET_CHECKER" }),
    reset_watcher: () => dispatch({ type: "RESET_WATCHER" }),
    update_dashboard_layout: (data) =>
      dispatch({ type: "UPDATE_DASHBOARD_LAYOUT", data: data }),
  };
}

function mapStateToProps(state) {
  return {
    form_reset: state.res_req.form_reset,
    loading: state.res_req.loading,
    messageSuccess: state.res_req.messageSuccess,
    messageError: state.res_req.messageError,
    current_checker: state.checker.current_checker,
    current_watcher: state.watcher.current_watcher,
    watchers: state.watcher.watchers,
    user: state.user,
    loading_location: state.loc.loading_location,
    countries: state.loc.countries,
    states: state.loc.states,
    cities: state.loc.cities,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Index));
