export const listChecker = {
  en: {
    "list-modal-delete-ok": "Delete",
    "list-modal-delete-cancel": "Cancel",
    "list-modal-delete-title": "Watch out!",
    "list-modal-delete-content": "You are going to delete this entity, this cannot be undone. Do you confirm?",
    "list-modal-healthy-ok": "Yes it is.",
    "list-modal-healthy-title": "Healthy again ?",
    "list-modal-healthy-content": "Is everything back on track?",
    "list-last-failure-text": "Last failure",
    "list-no-failure-text": "Never failed yet",
    "greetings-sider-ok-title": "Hey, hello",
    "greetings-sider-ok-subtitle": "All your checkers are doing great! 👌",
    "greetings-sider-notok-title": "Sorry to bother you",
    "greetings-sider-notok-subtitle": "We have found some problems with one of your checker! 🔥",
    "greetings-sider-notok-subtitle-plus": "are of your checkers are in trouble! 🔥",
  },
  fr: {
    "list-modal-delete-ok": "Supprimer",
    "list-modal-delete-cancel": "Annuler",
    "list-modal-delete-title": "Attention !",
    "list-modal-delete-content": "Vous allez supprimer cet objet. Vous êtes certain ?",
    "list-modal-healthy-ok": "C'est en ordre !",
    "list-modal-healthy-content": "Tout est rentré en ordre ?",
    "list-last-failure-text": "Dernier raté",
    "list-no-failure-text": "Aucun",
    "greetings-sider-ok-title": "Bonjour",
    "greetings-sider-ok-subtitle": "Tous vos checkers sont OK 👌",
    "greetings-sider-notok-title": "Hey",
    "greetings-sider-notok-subtitle": "Nous avons rencontré un problème avec un de vos checker ! 🔥",
    "greetings-sider-notok-subtitle-plus": "de vos checkers ont rencontré un problème ! 🔥",
  }
};
