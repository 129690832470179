import React, * as react from "react";
//import WidgetCTA from "./WidgetCTA.js";
import API from "./../../ApiLayer/index.js";
import { useTranslation } from "react-i18next";
import { message, Input, Icon, Tag, Select, AutoComplete } from "antd";
import Reward from "react-rewards";

const { Option } = Select;

const AddASERP = (props) => {
  const { t } = useTranslation();
  const [kws, setKws] = react.useState([]);
  const [pristine, changePristine] = react.useState(true);
  const getKw = async function () {
    let response = await API.getInfoAboutRoute(props.route_to_check, "seo");
    if (response !== undefined && response.status_code === 200) {
      setKws(response.tokens);
    }
    return;
  };
  const onFocus = () => {
    if (kws.length === 0 && pristine) {
      getKw();
    }
    changePristine(false);
  };
  let reward = React.useRef("reward");

  const inputOptions = kws.map((kw) => (
    <Option value={kw} key={kw}>
      {kw}{" "}
      <span className="found-on-your-site">
        {t("checker-found-on-your-site")}
      </span>
    </Option>
  ));
  const countryOptions = props.countries.map((c) => (
    <Option app_id={c.app_id} key={c.name}>
      {c.name}
    </Option>
  ));
  const stateOptions = props.states.map((c) => (
    <Option app_id={c.app_id} key={c.name}>
      {c.name}
    </Option>
  ));
  const cityOptions = props.cities.map((c) => (
    <Option app_id={c.app_id} key={c.name}>
      {c.name}
    </Option>
  ));
  const onClick = () => {
    if (!props.is_pro) {
      reward.punishMe(); //weird method name but hey
      message.info(t("only-in-pro-alert"));
      return;
    }
  };

  return (
    <div className="inner-SERPModal">
      <div className="kw-input">
        <span className="label">{t("enter_serp_kw")}</span>
        <AutoComplete
          className="certain-category-search"
          dropdownClassName="certain-category-search-dropdown"
          dropdownMatchSelectWidth={true}
          size="large"
          style={{ width: "100%" }}
          dataSource={inputOptions}
          placeholder={t("kw_to_track")}
          optionLabelProp="value"
          filterOption={true}>
          <Input
            maxLength = {50}
            onBlur={(e) => props.handleKWChange(e.target.value)}
            onFocus={onFocus}
            size="large"
          />
        </AutoComplete>
      </div>
      <br />
      <div className="location-selector">
        <div className="a-loc-select">
          <span className="label">{t("enter_your_country")}</span>
          <Select
            className="a-loc-select"
            style={{ width: "100%" }}
            showSearch
            value={props.selected.country}
            placeholder="input search country"
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={true}
            countries={props.countries}
            onChange={(v, e) => props.handleCountryChange(v, e)}
            notFoundContent={null}>
            {countryOptions}
          </Select>{" "}
        </div>
        <div className="a-loc-select">
          <span className="label">{t("enter_your_state")}</span>
          <Select
            className="a-loc-select"
            style={{ width: "100%" }}
            loading={
              props.selected.country != null && props.states.length === 0
            }
            showSearch
            value={props.selected.state}
            placeholder="input search State"
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={true}
            onChange={(v, e) => props.handleStateChange(v, e)}
            disabled={props.selected.country == null}
            notFoundContent={null}>
            {stateOptions}
          </Select>{" "}
        </div>

        <div className="a-loc-select" onClick={() => onClick()}>
          <span className="label">{t("enter_your_city")}</span>
          <Reward
            style={{ width: "100%" }}
            key={props.key}
            ref={(ref) => {
              reward = ref;
            }}
            type="emoji">
            <Tag color="blue">
              <Icon type="star" /> PRO
            </Tag>
            <Select
              style={{ width: "100%" }}
              showSearch
              placeholder="input search City"
              defaultActiveFirstOption={false}
              showArrow={false}
              value={props.selected.city}
              filterOption={true}
              onChange={(v, e) => props.handleCityChange(v, e)}
              notFoundContent={null}
              disabled={props.selected.state == null || !props.is_pro}>
              {cityOptions}
            </Select>
          </Reward>
        </div>
      </div>
    </div>
  );
};

export default AddASERP;
