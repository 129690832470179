import React from "react";
import CustomSwitchFormItem from "./CustomSwitchFormItem.js";
import { Form } from "antd";

export class FormStepFeature extends React.Component {
  render() {
    const { t } = this.props.t;
    const { form } = this.props;
    const { getFieldDecorator } = form;
    return (
      <>
        <div className="switcher-CustomSwitchFormItem">
          {["seo_should_to_check", "dev_mode_to_check", "ssl_should_to_check"].map(
            (key) => (

              <Form.Item
                style={{
                  display: this.props.currentStep === 1 ? "inherit" : "none",
                }}
                {...this.props.switchItemLayout}
                name={key}
                key={key}
                className="a_switch">
                {getFieldDecorator(key, {
                  initialValue:this.props.data[key], 
                  valuePropName: "checked",
                })(
                  <CustomSwitchFormItem
                    feature={key}
                    user_plan={this.props.user.user_plan}
                    is_pro={["ssl_should_to_check","status_page_to_check"].includes(key)}
                  />
                )}
              </Form.Item>
            )
          )}
        </div>
      </>
    );
  }
}
