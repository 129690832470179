import React, { Component } from "react";
import { MuuriComponent } from "muuri-react";
import { Link } from "react-router-dom";
import { Spin, Switch, Badge, Icon, Tooltip } from "antd";
import WidgetCurveGraph from "./Widget/WidgetCurveGraph.js";
import WidgetScore from "./Widget/WidgetScore.js";
import WidgetScoresGraph from "./Widget/WidgetScoresGraph.js";

export class DashboardGrid extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataSetCreated: false,
      metrics: [],
      layout: this.props.layout,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.layout !== this.props.layout) {
      this.setState({ layout: prevProps.layout });
    }
  }
  render() {
    const { t } = this.props;
    const options = {
      dragSortHeuristics: {
        sortInterval: 70,
      },
      //      layout: {
      //        fillGaps: true,
      //        rounding: false,
      //        alignLeft: true,
      //      },
      layoutDuration: 400,
      dragRelease: {
        duration: 400,
        easing: "ease-out",
      },
      dragEnabled: this.props.dragEnabled,
      dragCssProps: { touchAction: "auto" },
      dragContainer: document.body,
      dragPlaceholder: {
        enabled: true,
        createElement: function (item) {
          return item.getElement().cloneNode(true);
        },
      },
      // The placeholder of an item that is being dragged.
    };

    return (
      <div className="grid muuri">
        {this.props.c.seo_report !== undefined &&
        this.props.c.seo_report !== null &&
        this.props.c.seo_report.length !== 0 ? (
          <MuuriComponent
            sort={this.state.layout}
            ref={this.props.gridRef}
            {...options}>
            {Object.keys(this.props.c.seo_report.scores).map((attr) => {
              return (
                <WidgetScore
                  mobile={this.props.mobile}
                  key={attr}
                  dataSet={attr}
                  dragEnabled={this.props.dragEnabled}
                  data={this.props.c.seo_report.scores[attr]}
                />
              );
            })}
            {Object.keys(this.props.c.seo_report.metrics).map((attr) => {
              return (
                <WidgetCurveGraph
                  mobile={this.props.mobile}
                  key={`score-progress-${attr}`}
                  dataSet={attr}
                  dragEnabled={this.props.dragEnabled}
                  data={this.props.c.seo_report.metrics[attr]}
                />
              );
            })}
            {Object.keys(this.props.c.seo_report.scores).map((attr) => {
              return (
                <WidgetScoresGraph
                  mobile={this.props.mobile}
                  key={`score-graph-${attr}`}
                  dataSet={attr}
                  dragEnabled={this.props.dragEnabled}
                  data={this.props.c.seo_report.scores[attr]}
                />
              );
            })}
          </MuuriComponent>
        ) : (
          <div style={{ minHeight: "60px" }}></div>
        )}
      </div>
    );
  }
}
