import { put } from "redux-saga/effects";
import API from "./../../ApiLayer/index.js";
// worker Saga: will be fired on USER_FETCH_REQUESTED actions

export function* getCountries(data) {
  yield put({ type: "GETTING_COUNTRIES" });
  try {
    const countries = yield API.getCountries(data);
    yield put({ type: "GET_COUNTRIES_SUCCESS", payload: countries });
  } catch (e) {
    yield put({ type: "GET_COUNTRIES_FAILURE", payload: e });
  }
}
export function* getStates(data) {
  yield put({ type: "GETTING_STATES" });
  try {
    const states = yield API.getStates(data);
    yield put({ type: "GET_STATES_SUCCESS", payload: states });
  } catch (e) {
    yield put({ type: "GET_STATES_FAILURE", payload: e });
  }
}
export function* getCities(data) {
  yield put({ type: "GETTING_CITIES" });
  try {
    const cities = yield API.getCities(data);
    yield put({ type: "GET_CITIES_SUCCESS", payload: cities });
  } catch (e) {
    yield put({ type: "GET_CITIES_FAILURE", payload: e });
  }
}
